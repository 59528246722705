import { useState } from 'react';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { notify, notifyError } from '../../components/AlartNotify/AlertNotify';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Lütfen E-posta adresi gir.')
    .min(3, 'En az 3 karakter olmalı!')
    .max(50, 'En fazla 50 karakter olmalı!')
    .required('Lütfen e-posta adresini gir.'),
  password: Yup.string().min(6, 'En az 6 karakter olmalı!').max(50, 'En fazla 50 karakter olmalı!').required('Lütfen şifreni gir'),
});

const initialValues = {
  email: '',
  password: '',
};
export default function GiveaGift() {
  const [error, setError] = useState('');
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {},
  });
  return (
    <div>
      <div className="pt-5 mt-5">
        <Container>
          <Row>
            <Col md="6">Hediye Kartı</Col>
            <Col md="6">
              <Form className="mt-4" onSubmit={formik.handleSubmit} noValidate>
                <Row>
                  <Col md="6">
                    {' '}
                    <Form.Group>
                      <Form.Label htmlFor="exampleInputEmail1" className="custom-label">
                        Adınız
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...formik.getFieldProps('email')}
                        name="email"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Satın alan kişinin adını giriniz"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.email}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Label htmlFor="exampleInputEmail1" className="custom-label">
                      Soyadınız
                    </Form.Label>
                    <Form.Group className="position-relative">
                      <Form.Control
                        {...formik.getFieldProps('password')}
                        type="text"
                        name="password"
                        className="form-control mb-0"
                        id="exampleInputPassword2"
                        placeholder="Satın alan kişinin soyadını giriniz"
                        required
                      />

                      {formik.touched.password && formik.errors.password && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.password}
                            </span>
                          </div>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    {' '}
                    <Form.Group>
                      <Form.Label htmlFor="exampleInputEmail1" className="custom-label">
                        E-Postanız
                      </Form.Label>
                      <Form.Control
                        type="email"
                        {...formik.getFieldProps('email')}
                        name="email"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Satın alan kişinin e-posta adresini giriniz"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.email}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    {' '}
                    <Form.Group>
                      <Form.Label htmlFor="exampleInputEmail1" className="custom-label">
                        Hediye Edilen kişinin adı
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...formik.getFieldProps('email')}
                        name="email"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Hediye edilen kişinin adı"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.email}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="position-relative">
                      <Form.Label htmlFor="exampleInputEmail1" className="custom-label">
                        Hediye Edilen kişinin soyadı
                      </Form.Label>
                      <Form.Control
                        {...formik.getFieldProps('password')}
                        type="text"
                        name="password"
                        className="form-control mb-0"
                        id="exampleInputPassword2"
                        placeholder="Hediye edilen kişinin soyadı"
                        required
                      />

                      {formik.touched.password && formik.errors.password && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span className="text-primary" role="alert">
                              {formik.errors.password}
                            </span>
                          </div>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    {' '}
                    <Form.Group>
                      <Form.Label htmlFor="exampleInputEmail1" className="custom-label">
                        Hediye Edilen kişinin e-postası
                      </Form.Label>
                      <Form.Control
                        type="email"
                        {...formik.getFieldProps('email')}
                        name="email"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Hediye edilen kişinin e-posta adresi"
                        autoComplete="off"
                        required
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.email}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group>
                      <Form.Label htmlFor="exampleTextarea" className="custom-label">
                        Hediye mesajı
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        {...formik.getFieldProps('note')}
                        name="note"
                        className="form-control mb-0"
                        id="exampleTextarea"
                        placeholder="Hediye mesajını bırakabilirsiniz..."
                        autoComplete="off"
                      />
                      {formik.touched.note && formik.errors.note && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.note}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className="w-100">
                      <Form.Label className="custom-label">Gönderim Seçenekleri</Form.Label>
                      <div className="d-flex flex-row">
                        <Form.Check
                          type="radio"
                          id="sendNow"
                          label="Hemen Gönder"
                          value="now"
                          {...formik.getFieldProps('sendOption')}
                          name="sendOption"
                          className="form-check-inline me-3 small-radio"
                        />
                        <Form.Check
                          type="radio"
                          id="scheduleSend"
                          label="Gönderim Zamanı Belirle"
                          value="schedule"
                          {...formik.getFieldProps('sendOption')}
                          name="sendOption"
                          className="form-check-inline me-3"
                        />
                      </div>
                      {formik.touched.sendOption && formik.errors.sendOption && (
                        <div className="fv-plugins-message-container">
                          <span className="text-primary" role="alert">
                            {formik.errors.sendOption}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  {/* <div>
                    <Button type="submit" className="btn btn-hover btn-primary1 w-100">
                      Giriş Yap
                    </Button>
                  </div> */}
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
