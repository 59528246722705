import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Navbar, Dropdown, Nav } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import CustomToggle from '../../../../components/dropdowns';
import { ReactComponent as Search } from '../../../../assets/icons/search.svg';
import logo from '../../../../assets/images/wise&rise.png';
import { Profile } from './Profile';
import { LoginBtn } from './loginBtn';
import { useAuth } from '../../../../contexts/AuthContext';
import Categories from './components/Categories';
import { useApi } from '../../../../contexts/RequestContext';
import { isMobile } from '../../../../utils/isMobil';
import { Modal } from 'react-bootstrap';
import IsGiftCoupone from '../../../CouponeSignUp/IsGiftCoupone';
import { ModalHeader } from '../../../ModalHeader';

const HeaderStyle1 = (props) => {
  const history = useHistory();
  const { inLogin, isAdmin, profile, user, openMenu, openRightMenu } = useAuth();
  const [bgColor, setBgColor] = useState(false);
  const { setCat } = useApi();
  const [isBussiness, setIsBusiness] = useState(false);

  let page = window.location.pathname.includes('watching');

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      if (scrollY >= 20) {
        setBgColor(true);
      } else setBgColor(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const openLoginPage = () => {
    if (inLogin) openRightMenu();
    else history.push('/login');
  };
  useEffect(() => {
    if (profile && !profile?.is_survey_completed) history.push('/survey');
    if (window.location.pathname == '/business') setIsBusiness(true);
    else setIsBusiness(false);
  }, [window.location.pathname]);
  return (
    <>
      <header id="main-header" style={{ zIndex: '1001' }}>
        <div className={`${page || bgColor ? 'bg-black' : ''} main-header`}>
          <Container>
            <Row className="row-margın-right-0">
              <Col sm="12" className="tex-center">
                <Navbar expand="lg" className="p-0 text-center">
                  {!inLogin || profile ? (
                    <Navbar.Toggle onClick={openMenu} className="c-toggler">
                      <div className="navbar-toggler-icon">
                        <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                        <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                        <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                      </div>
                    </Navbar.Toggle>
                  ) : (
                    <div></div>
                  )}

                  <Navbar.Brand className="navbar-brand text-center" href="/" title="Wise&Rise">
                    <img
                      className="img-fluid logo"
                      style={{
                        height: '50px',
                        width: 'auto',
                        marginLeft: '5%',
                      }}
                      src={logo}
                      alt="Wise&Rise"
                    />
                  </Navbar.Brand>

                  <Dropdown className="mobile-more-menu d-flex align-items-center justify-content-between">
                    {(!inLogin || profile) && isMobile() && (
                      <div as={CustomToggle} variant="search-toggle">
                        <Link to={'/search'} className="mr-3">
                          <Search className="hover-primary " />
                        </Link>
                      </div>
                    )}

                    <Dropdown.Toggle onClick={openLoginPage} className="d-block more-menu" as={CustomToggle} variant="more-toggle">
                      <div className="d-block d-md-none">
                        {inLogin && profile && (
                          <div className=" d-inline-block ">
                            {profile ? (
                              <img
                                src={profile?.avatar?.path}
                                className="profile-pic  rounded img-fluid"
                                alt="user"
                                style={{ height: '40px', width: 'auto' }}
                              />
                            ) : (
                              <div
                                className="profile-pic avatar-30 rounded-circle text-white d-flex justify-content-center align-items-center"
                                style={{
                                  fontSize: '15px',
                                  background: '#BE161A',
                                }}
                              >
                                {user?.email?.toUpperCase()?.slice(0, 1)}
                                {user?.email?.toUpperCase()?.slice(1, 2)}
                              </div>
                            )}
                          </div>
                        )}

                        {!inLogin && <i style={{ fontSize: '24px' }} class="fa fa-user"></i>}
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="d-none d-lg-block more-menu">
                      <div className="navbar-right position-relative">
                        <ul className="d-flex align-items-center justify-content-end list-inline m-0">
                          <Dropdown as="li" className="nav-item m-">
                            <Dropdown.Toggle as={CustomToggle} variant="search-toggle">
                              <Link to={'/search'}>
                                <Search className="hover-primary" />
                              </Link>
                            </Dropdown.Toggle>
                          </Dropdown>
                          {inLogin ? <Profile /> : <LoginBtn />}
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Navbar.Collapse className="navbar-left d-none d-lg-block">
                    <div className="menu-main-menu-container">
                      <Nav as="ul" id="top-menu" className="text-left">
                        {(!inLogin || (inLogin && profile !== null)) && (
                          <li className="menu-item ">
                            <Link
                              to="/kategoriler"
                              onClick={() => setCat('kategoriler')}
                              className="dropdown-toggle"
                              style={{ fontSize: '14px' }}
                              title={'Kategoriler'}
                            >
                              Kategoriler
                            </Link>
                            <Categories />
                          </li>
                        )}

                        {!inLogin && !isBussiness && (
                          <li className="menu-item">
                            <Link to="/uyelik-planlari" style={{ fontSize: '14px' }} title="Planlar">
                              Planlar
                            </Link>
                          </li>
                        )}
                        {!isBussiness && (
                          <li className="menu-item">
                            <Link to="/business" style={{ fontSize: '14px' }} title="Business">
                              Business
                            </Link>
                          </li>
                        )}
                        {/* <li className="menu-item">
                          <Link to="#" onClick={handleOpenModal} style={{ fontSize: '14px' }} title="Kupon Kodu">
                            Hediye Kuponu Kullan
                          </Link>
                        </li> */}

                        {inLogin && profile !== null && (
                          <>
                            <li className="menu-item">
                              <Link onClick={() => setCat('kategoriler')} to="/kategoriler" style={{ fontSize: '14px' }} title="Keşfet">
                                Keşfet
                              </Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/my-progress" style={{ fontSize: '14px' }}>
                                Aktivitelerim
                              </Link>
                            </li>
                            {/* {isAdmin && (
                              <li className="menu-item">
                                <Link to="/admin-page" style={{ fontSize: '14px' }}>
                                  Admin
                                </Link>
                              </li>
                            )} */}
                          </>
                        )}
                      </Nav>
                    </div>
                  </Navbar.Collapse>

                  <div className=" navbar-right menu-right">
                    <ul className=" d-flex align-items-center list-inline m-0">
                      <Dropdown as="li" className="nav-item nav-icon">
                        <Dropdown.Toggle as={CustomToggle} href="/search" variant="search-toggle device-search">
                          <Link to={'/search'}>
                            <Search className="hover-primary" />
                          </Link>
                        </Dropdown.Toggle>
                      </Dropdown>

                      {inLogin ? <Profile /> : <LoginBtn />}
                    </ul>
                  </div>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default HeaderStyle1;
