import React, { useState } from 'react';
import { Col, Row, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';
import logo from '../../../../assets/images/wise&rise.png';
import SendMail from '../../../SendMail';
import { useApi } from '../../../../contexts/RequestContext';
import IsGiftCoupone from '../../../CouponeSignUp/IsGiftCoupone';
import { ModalHeader } from '../../../ModalHeader';

const FooterStyle = (props) => {
  const { categories, setCat } = useApi();
  const [show, setShow] = useState(false);
  const handleOpenModal = () => {
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
  };
  return (
    <>
      <footer id="contact" className="footer-one iq-bg-dark" style={{ background: '#000' }}>
        <div className="footer-top">
          <Container>
            <Row className="footer-standard">
              <Col lg="2" md="6" className="d-none d-sm-block">
                <div className="widget text-left mb-5">
                  <div className="menu-footer-link-1-container">
                    <ul id="menu-footer-link-1" className="menu p-0">
                      <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314 text-white">
                        <Link to="/kategoriler" style={{ fontWeight: '700' }}>
                          Keşfet
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-list text-left">
                  <ul>
                    <li>
                      <Link to="/hakkimizda">Hakkımızda</Link>
                    </li>
                    <li>
                      <Link to="/iletisim" title="Bize Ulaşın">
                        İletişim
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="3" md="6" className="d-none d-sm-block">
                <div className="widget text-left mb-5">
                  <div className="menu-footer-link-1-container">
                    <ul id="menu-footer-link-1" className="menu p-0">
                      <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314 text-white">
                        <div style={{ fontWeight: '700', color: '#fff' }}>Kurumsal</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-list text-left">
                  <ul>
                    <li>
                      <Link to="/sikca-sorulan-sorular" title="Sıkça Sorulan Sorular">
                        Sıkça Sorulan Sorular
                      </Link>
                    </li>
                    <li>
                      <Link to="/iletisim" title="Yardım Merkezi ">
                        Yardım Merkezi
                      </Link>
                    </li>
                    <li>
                      <Link to="/uyelik-sozlesmesi" title="Abonelik Şartları">
                        Abonelik Şartları
                      </Link>
                    </li>
                    <li>
                      <Link to="/gizlilik-politikasi" title="Gizlilik Politikası">
                        Gizlilik Politikası
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="3" md="6" className="d-none d-sm-block">
                <div className="widget text-left mb-5">
                  <div className="menu-footer-link-1-container">
                    <ul id="menu-footer-link-1" className="menu p-0">
                      <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314 text-white">
                        <div style={{ fontWeight: '700', color: '#fff' }}>Tüm Kategoriler</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-list text-left">
                  <ul>
                    {categories?.map((item, index) => {
                      return (
                        <li key={index}>
                          <Link onClick={() => setCat(item)} to={`/${item.slug}`} title={item?.name}>
                            {item?.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
              <Col lg="4" md="6" sm="12" className="mt-4 mt-lg-0">
                <div className="widget text-center mb-5 d-none d-sm-block">
                  <div className="menu-footer-link-1-container">
                    <ul id="menu-footer-link-1" className="menu p-0">
                      <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314 text-white">
                        <Link style={{ fontWeight: '700', color: '#fff' }} to="/give-gift">
                          Hediye et
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className=" btn btn-hover  d-none d-md-block mb-4 px-1 w-75 " onClick={handleOpenModal}>
                    Hediye Kuponu Kullan
                  </div>
                </div>
                {/* <div className="text-left">
                  <div className="widget text-left d-none d-md-block mb-4">
                    <div className="textwidget">
                      <p>
                        <small>E-posta Adresi</small>
                      </p>
                    </div>
                  </div>
                  <SendMail />
                  <ul className="info-share text-center text-sm-left">
                    <li>
                      <a target="_blank" href="https://www.facebook.com/wisenrisetr">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://twitter.com/wise_n_rise">
                        <img src={twitter} />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.tiktok.com/@wise_n_rise">
                        <img src={tiktok} />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.youtube.com/@wise_n_rise">
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.instagram.com/wise_n_rise/">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.linkedin.com/company/wisenrise/">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>

                  <div className="mt-3 text-center">
                    <img src={Iyzico} alt="Iyzico logo" />
                  </div>
                </div> */}
              </Col>
            </Row>
            <Row className="mt-5">
              <Col sm="12" md="3" lg="3" className="d-flex justify-content-center justify-content-sm-start">
                <div className="footer-logo ">
                  <img className="img-fluid logo" src={logo} alt="Wise&Rise" />
                </div>
              </Col>
              <Col sm="12" md="9" lg="9" className="text-left text-sm-left">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  <Link to="/gizlilik-politikasi" className="mr-5" title="Gizlilik Politikası">
                    Gizlilik Politikası
                  </Link>
                  <Link to="/uyelik-sozlesmesi" className="" title="Şartlar ve Koşullar">
                    Şartlar ve Koşullar
                  </Link>
                  {/* <Link to="#" className="mr-5">
                    Sitemap
                  </Link> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal size="lg" show={show} onHide={handleCloseModal} centered>
          <Modal.Body style={{ background: '#000000', overflow: 'hidden' }}>
            <ModalHeader handleClose={handleCloseModal} />
            <IsGiftCoupone handleClose={handleCloseModal} />
          </Modal.Body>
        </Modal>
      </footer>
    </>
  );
};

export default FooterStyle;
